import Lazyload from "lazyload"

class LoadMorePosts {
  constructor(postsPageID) {
    this.root_url = localized?.root_url ?? ""
    // console.log("root url from module laod more posts", this.root_url)
    this.lastPostObserver
    this.postsLoading
    this.postsPageID = postsPageID ?? 0
    this.newsPageContent = jQuery(`#${this.postsPageID}`)
    this.observeLastPost()
  }

  events() {}

  loadMorePosts() {
    if (!this.postsLoading && this.newsPageContent.length) {
      this.postsLoading = true
      const offset = jQuery(".post-item-content__post").length // number of loaded post items
      jQuery.getJSON(`${localized.root_url}/wp-json/wp/v2/posts?per_page=${localized.posts_per_page}&offset=${offset}&orderby=menu_order&order=asc`, posts => {
        // console.log(posts)
        if (posts.length) {
          this.newsPageContent.append(`
          ${posts
            .map(
              post => `
          <div id="${post.id}" class="post-item-content__post article" data-id="${post.id}" data-history="${post.slug}" data-title="${post.title.rendered}">
          ${
            post.featured_image
              ? `
            <div class="journal-space">
              <img class="lazyload img-container img-only journal-${post.featured_image.class}" 
              data-src="${post.featured_image.src}" 
              data-srcset="${post.featured_image.srcset}" 
              data-width="${post.featured_image.width}"
              data-height="${post.featured_image.height}"
              sizes="(max-width: 380px) 100vw, (max-width: 700px) 50vw, 30vw"
              alt="${post.featured_image.alt}">
            </div>
          `
              : ``
          }

          <div class="post-item-content cv-container reading-text">
            <div class="reading-text journal-text">
              <span class="journal-date">${post.title.rendered} — </span>
              ${post.content.rendered}
            </div>
          </div>
        </div>
          `
            )
            .join("")}
        `)
          this.addLazyloadToElement(document.querySelector(`[id="${this.postsPageID}"]`))
          this.observeLastPost()
          this.postsLoading = false
        } else {
          // no more posts
          this.lastPostObserver.disconnect()
          this.newsPageContent.append(`
          <div class="post-item-content__post article">
            <div class="journal-date nach-oben-button">nach oben</div>
          </div>`)
          this.newsPageContent.find(".nach-oben-button").on("click", function () {
            jQuery("html, body").animate({
              scrollTop: 0
            })
          })
        }
      })
    }
  }

  observeLastPost() {
    if (this.lastPostObserver) {
      this.lastPostObserver.disconnect()
    } else {
      this.lastPostObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("Last Post ENTER")
            this.loadMorePosts()
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const lastPost = document.querySelector(".post-item-content__post:last-child")

    lastPost ? this.lastPostObserver.observe(lastPost) : ""
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   jQuery(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default LoadMorePosts
